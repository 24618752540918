import React from 'react';

import site from '../data/site.json';

const DEFAULT_CLASSNAME: string = `skillspage`;
const HEADER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__header`;
const MAINCONTAINER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__maincontainer`;
const SUBHEADER_CLASSNAME: string = `${MAINCONTAINER_CLASSNAME}__subheader`;
const SUBCONTAINER_CLASSNAME: string = `${MAINCONTAINER_CLASSNAME}__subcontainer`;
const SKILLSSECTIONCONTAINER_CLASSNAME: string = `${SUBCONTAINER_CLASSNAME}__skillssectioncontainer`;
const SECTIONHEADER_CLASSNAME: string = `${SKILLSSECTIONCONTAINER_CLASSNAME}__sectionheader`;
const SECTIONITEM_CLASSNAME: string = `${SKILLSSECTIONCONTAINER_CLASSNAME}__sectionitem`;

const SkillsPage = (): JSX.Element => {
  return (
    <div className={DEFAULT_CLASSNAME}>
      <div className={HEADER_CLASSNAME}>{site.skillsPage.mainheader}</div>

      <div className={MAINCONTAINER_CLASSNAME}>
        <div className={SUBHEADER_CLASSNAME}>Professional Skills</div>
        <div className={SUBCONTAINER_CLASSNAME}>
          <div className={SKILLSSECTIONCONTAINER_CLASSNAME}>
            <div className={SECTIONHEADER_CLASSNAME}>{site.skillsPage.languageHeader}</div>
            {bulletpointSections(site.skillsPage.language)}
          </div>
          <div className={SKILLSSECTIONCONTAINER_CLASSNAME}>
            <div className={SECTIONHEADER_CLASSNAME}>{site.skillsPage.htmlcssHeader}</div>
            {bulletpointSections(site.skillsPage.htmlcss)}
          </div>
          <div className={SKILLSSECTIONCONTAINER_CLASSNAME}>
            <div className={SECTIONHEADER_CLASSNAME}>{site.skillsPage.frontendHeader}</div>
            {bulletpointSections(site.skillsPage.frontend)}
          </div>
          <div className={SKILLSSECTIONCONTAINER_CLASSNAME}>
            <div className={SECTIONHEADER_CLASSNAME}>{site.skillsPage.backendHeader}</div>
            {bulletpointSections(site.skillsPage.backend)}
          </div>
          <div className={SKILLSSECTIONCONTAINER_CLASSNAME}>
            <div className={SECTIONHEADER_CLASSNAME}>{site.skillsPage.extrasHeader}</div>
            {bulletpointSections(site.skillsPage.extras)}
          </div>
        </div>
        <div className={SUBHEADER_CLASSNAME}>Personal Skills</div>
          <div className={SUBCONTAINER_CLASSNAME}>
          <div className={SKILLSSECTIONCONTAINER_CLASSNAME}>
            <div className={SECTIONHEADER_CLASSNAME}>{site.skillsPage.extrasHeader}</div>
            {bulletpointSections(site.skillsPage.extraSkills.extras)}
          </div>
        </div>
      </div>
    </div>
  );
}

function bulletpointSections(section: string[]): JSX.Element[] {
  return section.map((item: string) => {
    return <div className={SECTIONITEM_CLASSNAME}>{item}</div>;
  });
}

export default SkillsPage